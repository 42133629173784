/*
 * @Author: ch3nh2
 * @Date: 2022-09-19 13:47:19
 * @LastEditors: ch3nh2
 * @LastEditTime: 2022-09-22 13:51:30
 * @FilePath: /beilunchanye_fe_20211208/src/api/zgc.js
 * @Description: none
 */
import service from '@/utils/http'

// 总览
export function getOverview(params) {
  return service({
    url: 'zgcds_yuanqu',
    method: 'get',
    params
  })
}

// 招商园区概况
export function getBaseData(params) {
  return service({
    url: 'zgcds_zhaoshang',
    method: 'get',
    params
  })
}

// 未租赁情况
export function getNotLeased(params) {
  return service({
    url: 'zgcds_not_rent',
    method: 'get',
    params
  })
}

// 已租赁情况
export function getLeased(params) {
  return service({
    url: 'zgcds_has_rent',
    method: 'get',
    params
  })
}

// 企业列表
export function getCompanyList(params) {
  return service({
    url: 'company_list_fe',
    method: 'get',
    params
  })
}