<template>
  <div class="title_wrap">
    <div class="title_box">
      <img class="title_icon" src="@/assets/images/zhongguancunTitleIcon.png" alt="">
      <div class="title_cont">
        {{txt}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    txt: {
      default: '',
      type: String
    }
  },
}
</script>
<style lang="less" scoped>
.title_wrap {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 412px;
    height: 1px;
    background-image: url('../assets/images/zgcXian.png');
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .title_box {
    display: flex;
    align-items: center;
    padding-bottom: 4px;
    position: relative;
  }

  .title_icon {
    width: 16px;
    height: 16px;
  }

  .title_left {
    width: 24px;
    height: 24px;
    // background-image: linear-gradient(to right, #021b67, #04091f);
    position: relative;
    z-index: -1;
  }

  .title_cont {
    // width: 324px;
    padding-left: 3px;
    font-size: 19px;
    // border-left: 1px solid #99ccff;
    // background-image: linear-gradient(to right, #042049, rgba(4, 32, 73, 0));
    text-align: left;
  }
}
</style>